import React, { useState, useCallback, useRef } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const App = () => {
  const releaseURL = process.env.REACT_APP_RELEASE_URL;
  const [data, setData] = useState({});
  const emailRef = useRef();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    setData({ loading: true });

    const token = await executeRecaptcha("generate");
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ token: token, email: emailRef.current.value }),
    };

    fetch(process.env.REACT_APP_LICENSE_URL + "/generate", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setData({ loading: false, ...data });
      })
      .catch((err) => setData({ loading: false, error: err.message }));
  }, [executeRecaptcha]);

  return (
    <div className="lg:h-screen bg-gradient-to-r lg:bg-gradient-to-r from-[#2B3038] from-50% to-50% to-[#181C22]  w-screen flex flex-col justify-center items-center">
      <div className="lg:flex sm:w-full lg:w-fit flex-row h-full w-max-[1600px]">
        <div className="lg:w-7/12 bg-[#2B3038] flex justify-center items-center">
          <div className="max-w-2xl h-full px-5 lg:px-10 pt-24 pb-14">
            <div className="w-full h-full flex flex-col align-middle justify-center">
              <p className="uppercase font-bold text-4xl lg:text-5xl text-[#F1D9BE]">
                Easy Mobile Proxy <br />
              </p>
              <p className="mb-4 uppercase font-bold text-4xl lg:text-5xl text-[#EB874A]">
                Management
              </p>
              <p className="text-[#6E747A] text-base mb-4">
                Dongle Farm simplifies the process of setting up mobile proxies,
                making it accessible for people with no technical expertise. The
                user-friendly interface ensures that setting up your mobile
                proxy network is a breeze, eliminating the need for deep
                technical knowledge.
              </p>
              <div className="h-[60px]"></div>
              <div className="text-[#F1D9BE] text-2xl mb-2">
                That's what you need to get started
              </div>
              <p className="text-[#6E747A] text-base">
                1. Plug your Huawei E3372 dongles into a Linux server (e.g.,
                Raspberry Pi).
              </p>
              <p className="text-[#6E747A] text-base">
                2. Connect your server to your home router.
              </p>
              <p className="text-[#6E747A] text-base">
                3. Install and run our software on your server.
              </p>
              <p className="text-[#6E747A] text-base mb-1">
                4. Create proxies using our software.
              </p>
              <p className="text-[#6E747A] text-base mb-4">
                Now, all devices connected to your home router can utilize your
                proxies.
              </p>
              <div className="lg:flex flex-row justify-between w-full">
                <div className="inline-flex items-center text-white text-base mr-2">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="7"
                      height="7"
                      rx="3.5"
                      stroke="#EB874A"
                      strokeWidth="3"
                    />
                  </svg>
                  <p className="text-white content-center whitespace-nowrap">
                    &nbsp;Dongle
                  </p>
                </div>

                <div className="inline-flex items-center text-white text-base mr-2">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="7"
                      height="7"
                      rx="3.5"
                      stroke="#EB874A"
                      strokeWidth="3"
                    />
                  </svg>
                  <p className="text-white content-center whitespace-nowrap">
                    &nbsp;Server (Linux)
                  </p>
                </div>

                <div className="inline-flex items-center text-white text-base mr-2">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="7"
                      height="7"
                      rx="3.5"
                      stroke="#EB874A"
                      strokeWidth="3"
                    />
                  </svg>
                  <p className="text-white content-center">&nbsp;Router</p>
                </div>

                <div className="inline-flex items-center text-white text-base mr-2">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="7"
                      height="7"
                      rx="3.5"
                      stroke="#EB874A"
                      strokeWidth="3"
                    />
                  </svg>
                  <p className="text-white content-center whitespace-nowrap">
                    &nbsp;Mobile Device
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-5/12 bg-[#181C22] w-full flex justify-center items-center px-5 lg:px-10 py-14">
          <div className="border-[#323539] border-[1px] max-w-md w-full rounded-[10px] py-8 lg:pt-[80px] px-5 lg:px-11">
            <div className="flex flex-col h-full justify-between">
              <div className="flex flex-col">
                <div className="mb-[30px] text-center text-[#F1D9BE] uppercase font-bold text-3xl">
                  Download app
                </div>
                <div className="flex w-full h-full justify-center items-center">
                  <a
                    className="mr-2 w-full hover:scale-105 transform transition duration-300"
                    href={`${releaseURL}/download?arch=amd64`}
                  >
                    <button className="w-full px-[20px] py-[10px] h-[40px] justify-between items-center bg-[#EB874A] inline-flex uppercase text-[#F1D9BE] font-bold">
                      AMD64
                      <div className="float-right">
                        <svg
                          width="15"
                          height="21"
                          viewBox="0 0 15 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.6 0H6V8.4H0.599998L7.8 16.8L15 8.4H9.6V0ZM15 21V18.6H0.599998V21H15Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </div>
                    </button>
                  </a>
                  <a
                    className="ml-2 w-full hover:scale-105 transform transition duration-300"
                    href={`${releaseURL}/download?arch=arm64`}
                  >
                    <button className="w-full px-[20px] py-[10px] h-[40px] justify-between items-center bg-[#EB874A] inline-flex uppercase text-[#F1D9BE] font-bold">
                      ARM64
                      <div className="float-right">
                        <svg
                          width="15"
                          height="21"
                          viewBox="0 0 15 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.6 0H6V8.4H0.599998L7.8 16.8L15 8.4H9.6V0ZM15 21V18.6H0.599998V21H15Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </div>
                    </button>
                  </a>
                </div>
              </div>
              <div className="flex flex-col">
                <p className="text-[#6E747A] text-base my-8">
                  The software is currently in its early development phase, so
                  you can try it for free with no registration.
                  <span className="text-[#EB874A]">
                    &nbsp;You only need an email to get your license.
                  </span>
                </p>
                <input
                  className="my-1 p-[10px] outline-none focus:border-[#EB874A] text-[#94A3B8] bg-[#2B3038] border-[1px] h-[40px] rounded-[8px] border-[#54565A]"
                  type="email"
                  maxLength="30"
                  placeholder="Email"
                  ref={emailRef}
                  required
                />
                <button
                  onClick={handleReCaptchaVerify}
                  className="group  my-1 mb-4 flex items-center justify-center text-[#EB874A] font-bold bg-[#2B3038] border-[1px] h-[40px] rounded-[8px] border-[#EB874A]"
                  disabled={data.loading}
                >
                  <p
                    style={{ display: data.loading ? "none" : "block" }}
                    className="group-hover:scale-105 transform transition duration-300"
                  >
                    Get license
                  </p>
                  <svg
                    width="24"
                    height="24"
                    style={{ display: data.loading ? "block" : "none" }}
                    viewBox="0 0 24 24"
                    fill="#EB874A"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="4" cy="12" r="3" opacity="1">
                      <animate
                        id="spinner_qYjJ"
                        begin="0;spinner_t4KZ.end-0.25s"
                        attributeName="opacity"
                        dur="0.75s"
                        values="1;.2"
                        fill="freeze"
                      />
                    </circle>
                    <circle cx="12" cy="12" r="3" opacity=".4">
                      <animate
                        begin="spinner_qYjJ.begin+0.15s"
                        attributeName="opacity"
                        dur="0.75s"
                        values="1;.2"
                        fill="freeze"
                      />
                    </circle>
                    <circle cx="20" cy="12" r="3" opacity=".3">
                      <animate
                        id="spinner_t4KZ"
                        begin="spinner_qYjJ.begin+0.3s"
                        attributeName="opacity"
                        dur="0.75s"
                        values="1;.2"
                        fill="freeze"
                      />
                    </circle>
                  </svg>
                </button>
                <div
                  className={`text-[#EB874A] min-h-6 items-center justify-center inline-flex ${data.message ? "hidden" : ""}`}
                >
                  {data.error}
                </div>
                <div
                  className={`text-[#56A86C] min-h-6 items-center justify-center inline-flex ${data.message ? "" : "hidden"}`}
                >
                  <svg
                    width="27"
                    height="20"
                    viewBox="0 0 27 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.14851 9.40654C0.757988 9.79707 0.757989 10.4302 1.14851 10.8208L9.1312 18.8034C9.52172 19.194 10.1549 19.194 10.5454 18.8034L12.4486 16.9003L3.75876 8.21051C3.36824 7.81998 2.73507 7.81998 2.34455 8.21051L1.14851 9.40654Z"
                      fill="#528861"
                    />
                    <path
                      d="M24.7929 3.14189C25.1834 3.53242 25.1834 4.16558 24.7929 4.55611L10.5456 18.8034C10.1551 19.1939 9.52193 19.1939 9.1314 18.8034L7.22826 16.9002L22.1826 1.94586C22.5732 1.55533 23.2063 1.55533 23.5969 1.94586L24.7929 3.14189Z"
                      fill="#56A86C"
                    />
                  </svg>
                  &nbsp; {data.message}
                </div>
              </div>
              <div
                className={`text-[#6E747A] text-sm mt-10 lg:mt-12 text-center`}
              >
                <div className="inline-flex text-nowrap">Contact Us:&nbsp;</div>
                <a
                  className="text-[#EB874A] cursor-pointer inline-flex"
                  href="mailto:support@dongle.farm"
                >
                  support@dongle.farm
                </a>
                &nbsp;
                <div className="inline-flex text-nowrap">Or&nbsp;</div>
                <a
                  rel="noreferrer"
                  className="text-[#EB874A] cursor-pointer inline-flex"
                  href="https://donglefarm.t.me"
                  target="_blank"
                >
                  Telegram&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0,0,256,256"
                    width="20px"
                    height="20px"
                  >
                    <g
                      fill="#eb874a"
                      fillRule="nonzero"
                      stroke="none"
                      strokeWidth="1"
                      strokeLinecap="butt"
                      strokeLinejoin="miter"
                      strokeMiterlimit="10"
                      strokeDasharray=""
                      strokeDashoffset="0"
                      fontFamily="none"
                      fontWeight="none"
                      fontSize="none"
                      textAnchor="none"
                      style={{ mixBlendMode: "normal" }}
                    >
                      <g transform="scale(5.12,5.12)">
                        <path d="M46.137,6.552c-0.75,-0.636 -1.928,-0.727 -3.146,-0.238h-0.002c-1.281,0.514 -36.261,15.518 -37.685,16.131c-0.259,0.09 -2.521,0.934 -2.288,2.814c0.208,1.695 2.026,2.397 2.248,2.478l8.893,3.045c0.59,1.964 2.765,9.21 3.246,10.758c0.3,0.965 0.789,2.233 1.646,2.494c0.752,0.29 1.5,0.025 1.984,-0.355l5.437,-5.043l8.777,6.845l0.209,0.125c0.596,0.264 1.167,0.396 1.712,0.396c0.421,0 0.825,-0.079 1.211,-0.237c1.315,-0.54 1.841,-1.793 1.896,-1.935l6.556,-34.077c0.4,-1.82 -0.156,-2.746 -0.694,-3.201zM22,32l-3,8l-3,-10l23,-17z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-sm bg-black w-full p-2 flex justify-center items-center text-[#6E747A]">
        Copyright © 2024 All rights reserved.
      </div>
    </div>
  );
};

export default App;
